<template>
    <app-layout class="tfa">
        <loader v-if="loading" />

        <card v-else class="tfa__outer">
            <template #icon>
                <clip-icon />
            </template>

            <template #title>{{ $t('tfa.title') }}</template>

            <div>
                <div class="tfa__qr">
                    <p>{{ $t('tfa.qr') }}</p>

                    <div v-html="qr" />
                </div>

                <card class="tfa__form">
                    <validation-observer ref="confirmObserver" v-slot="{ handleSubmit }" slim>
                        <form @submit.prevent="handleSubmit(confirm)">
                            <validation-provider name="secret_key" rules="required" v-slot="{ errors, reset }" slim>
                                <input-group
                                    :errors="errors"
                                    :description="$t('tfa.form.secret_key.description')"
                                    :label="$t('tfa.form.secret_key.label')"
                                    required
                                >
                                    <input-copy :value="form.secretKey" :placeholder="$t('tfa.form.secret_key.placeholder')" readonly />
                                </input-group>
                            </validation-provider>

                            <validation-provider name="code" rules="required" v-slot="{ errors, reset }" slim>
                                <input-group
                                    :errors="errors"
                                    :description="$t('tfa.form.code.description')"
                                    :label="$t('validation.names.code')"
                                    required
                                >
                                    <input-component
                                        v-model="form.appCode"
                                        :placeholder="$t('tfa.form.code.placeholder')"
                                        outlined
                                        @input="reset"
                                        @mousedown.stop
                                    />
                                </input-group>
                            </validation-provider>

                            <text-button :loading="form.loading" full>
                                {{ $t('tfa.form.button') }}
                                <connect-icon />
                            </text-button>
                        </form>
                    </validation-observer>
                </card>
            </div>

            <div class="tfa__text">
                <p>{{ $t('tfa.description.first') }}</p>

                <p>{{ $t('tfa.description.second') }}</p>
            </div>
        </card>

        <template #modal>
            <recovery-modal v-if="modal.opened" :codes="modal.codes" :close-button="false">
                <template #before>
                    <notification theme="success">{{ $t('tfa.modal.description') }}</notification>

                    <validation-observer ref="codesObserver" v-slot="{ handleSubmit }" slim>
                        <form @submit.prevent="handleSubmit(generateCodes)">
                            <validation-provider vid="password_confirmation" name="password" rules="required" v-slot="{ errors, reset }" slim>
                                <input-group :errors="errors" required>
                                    <input-component v-model="modal.password" type="password" :placeholder="$t('tfa.modal.form.password')" />
                                </input-group>
                            </validation-provider>

                            <text-button theme="secondary" full :disabled="modal.loading" :loading="modal.loading">
                                {{ $t('tfa.modal.form.button') }}
                                <retry-arrow-icon />
                            </text-button>
                        </form>
                    </validation-observer>
                </template>

                <template #after>
                    <text-button theme="success" :disabled="!modal.codes.length" full @click="finish">{{ $t('tfa.modal.finish') }}</text-button>
                </template>
            </recovery-modal>
        </template>
    </app-layout>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import { confirmTfa, enableTfa, recoveryCodes } from '@/services/skinsPaymentApi';
    import responseProcessingMixin from '@/mixins/responseProcessingMixin';
    import AppLayout from '@/pages/layout/AppLayout';
    import Card from '@/components/Card';
    import ClipIcon from '@/components/icons/ClipIcon';
    import ConnectIcon from '@/components/icons/ConnectIcon';
    import InputCopy from '@/components/inputs/InputCopy';
    import InputGroup from '@/components/inputs/InputGroup';
    import InputComponent from '@/components/inputs/InputComponent';
    import Loader from '@/components/Loader';
    import Notification from '@/components/Notification';
    import RecoveryModal from '@/components/tfa/RecoveryModal';
    import RetryArrowIcon from '@/components/icons/RetryArrowIcon';
    import TextButton from '@/components/buttons/TextButton';
    import Toast from '@/components/Toast';

    export default {
        name: 'Tfa',
        components: {
            AppLayout,
            Card,
            ClipIcon,
            ConnectIcon,
            InputComponent,
            InputCopy,
            InputGroup,
            Loader,
            Notification,
            RecoveryModal,
            RetryArrowIcon,
            TextButton,
            ValidationObserver,
            ValidationProvider,
        },
        mixins: [responseProcessingMixin],
        methods: {
            fetchTfa() {
                return enableTfa()
                    .then(({ data }) => {
                        this.form.secretKey = data.data.two_factor_secret;
                        this.qr = data.data.svg;
                    })
                    .catch(() =>
                        this.$toast({
                            component: Toast,
                            props: {
                                type: 'error',
                                message: this.$t('tfa.error'),
                            },
                        })
                    )
                    .finally(() => (this.loading = false));
            },
            generateCodes() {
                this.modal.loading = true;
                this.modal.codes = [];

                return recoveryCodes(this.modal.password)
                    .then(({ data }) => {
                        this.modal.codes = data;
                        this.modal.password = null;
                        this.$refs.codesObserver.reset();
                    })
                    .catch(({ response }) => this.processErrorResponse(response, this.$refs.codesObserver))
                    .finally(() => (this.modal.loading = false));
            },
            confirm() {
                this.form.loading = true;

                return confirmTfa(this.form.appCode)
                    .then(({ data }) => {
                        this.modal.codes = data;
                        this.modal.opened = true;
                    })
                    .catch(({ response }) => this.processErrorResponse(response, this.$refs.confirmObserver))
                    .finally(() => (this.form.loading = false));
            },
            finish: () => window.location.reload(),
        },
        data() {
            return {
                form: {
                    loading: false,
                    secretKey: null,
                    appCode: null,
                },
                loading: true,
                modal: {
                    codes: [],
                    loading: false,
                    opened: false,
                    password: null,
                },
                qr: null,
            };
        },
        mounted() {
            this.fetchTfa();
        },
    };
</script>

<style lang="scss" scoped>
    .tfa__form.card {
        padding-top: 10.5rem;
        background-color: #141926;
    }

    .tfa__qr {
        position: relative;
        display: table;
        margin: 0 auto -9rem;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18rem;
            height: 18rem;
            margin: 0 auto;
            border-radius: 1.2rem;
            background-color: #1a1e2e;

            ::v-deep {
                > img,
                > svg {
                    width: 80%;
                    height: auto;
                    border: 1px solid #ffffff;
                    border-radius: 0.8rem;
                }
            }
        }

        p {
            max-width: 22rem;
            margin: 0 auto 1.5rem;
            color: #a6b2d0;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1.2rem;
            font-weight: 325;
            line-height: 1.2;
            text-align: center;
        }
    }

    .tfa__text p {
        margin-top: 1.5rem;
        color: #8b99bb;
        font-family: 'Gotham Book', sans-serif;
        font-size: 1.2rem;
        font-weight: 325;
        line-height: 1.4;
    }

    .tfa .modal {
        width: 57rem;

        form .button {
            margin-top: 0.5rem;
        }

        ::v-deep .recovery-codes {
            margin-bottom: 0.5rem;
        }
    }

    @media screen and (min-width: 64em) {
        .tfa__outer::v-deep > .card__body {
            position: relative;
        }

        .tfa__form {
            &.card {
                padding-top: 1.5rem;
                padding-right: 22rem;
            }

            form {
                display: flex;

                > *:not(:first-child) {
                    margin: 0 0 0 1.5rem;
                }

                .button {
                    flex: 1 0 17rem;
                    margin-top: 4rem;
                }

                .input-group {
                    &:first-of-type {
                        flex: 1 1 auto;
                    }

                    &:last-of-type {
                        flex: 1 0 auto;
                    }
                }
            }
        }

        .tfa__qr {
            margin: 0 0 -10.3rem auto;
        }

        .tfa__text {
            position: absolute;
            top: 0;

            p {
                max-width: 70%;
                margin: 0 0 1.5rem;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    @media screen and (min-width: 90em) {
        .tfa__form form {
            .input-group {
                flex: 1 1 100% !important;
            }

            .button {
                flex: 0 0 34rem;
            }
        }
    }

    @media screen and (min-width: 120em) {
        .tfa__form {
            &.card {
                padding-top: 3rem;
                padding-right: 31rem;
            }

            form .button {
                margin-top: 4.7rem;
            }
        }

        .tfa__qr {
            margin: 0 3rem -13rem auto;

            div {
                width: 25rem;
                height: 25rem;
            }

            p {
                max-width: 24rem;
                font-size: 1.3rem;
            }
        }

        .tfa__text p {
            margin: 0 0 3rem;
            font-size: 1.5rem;
        }

        .tfa .modal {
            form .button {
                margin-top: 1rem;
            }

            ::v-deep .recovery-codes {
                margin-bottom: 1rem;
            }
        }
    }
</style>
